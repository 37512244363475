import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/Dialog.js'
import type { ComponentProps } from 'react'
import {
  Form,
  FormField,
  FormFieldError,
  FormFieldHint,
  FormFieldLabel,
  FormInput,
  FormSubmitButton
} from '~/components/Form'
import { useForm } from '~/hooks/useForm'
import { SubmitAppRequestSchema } from './routeConfig'

export const AppRequestDialog = (props: ComponentProps<typeof Dialog>) => {
  const form = useForm({
    schema: SubmitAppRequestSchema,
    action: '/api/requests',
    onSuccess: () => props.onOpenChange?.(false)
  })

  return (
    <Dialog {...props}>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>Request an app</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <div className="space-y-4 max-w-lg">
            <FormField name={form.keys.name}>
              <FormFieldLabel>App name*</FormFieldLabel>
              <FormInput required autoFocus placeholder="e.g. Pied Piper" />
              <FormFieldError />
            </FormField>

            <FormField name={form.keys.website}>
              <FormFieldLabel>Website*</FormFieldLabel>
              <FormInput required placeholder="e.g. https://piedpiper.com" />
              <FormFieldError />
            </FormField>

            <FormField name={form.keys.authenticatedUrl}>
              <FormFieldLabel>Authenticated URL</FormFieldLabel>
              <FormInput placeholder="e.g. https://app.piedpiper.com" />
              <FormFieldHint>
                The URL you visit when you're logged in to this app. Note that
                only web-based applications are supported.
              </FormFieldHint>
              <FormFieldError />
            </FormField>

            <FormSubmitButton className="min-w-32">Submit</FormSubmitButton>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
